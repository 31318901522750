<template>
  <div style="padding: 20px;">
    <v-layout wrap>
      <v-flex xs12>
        <search
          :onSearch="handleSearch"
          :showClear="searchValue && searchValue.length > 0 ? true : false"
          :maxWidth="400" 
        />
      </v-flex>
      <v-flex xs12>
        <small> [NOTA] <i>solo se mostrar�n m�ximo 10 elementos por grupo</i></small>
      </v-flex>

      <v-flex xs12 v-if="loading">
        <v-progress-linear
          indeterminate
          color="primary"
        />
      </v-flex>

      <v-flex xs12 v-else>
        <div v-if="result.androids.length > 0">
          <h1><v-icon large color="black">mdi-android</v-icon> {{$t('players.players', locale)}}</h1>
          <v-layout 
            wrap
            v-for="(item) of result.androids"
            :key="item.ID"
            style="cursor: pointer;margin: 1px 0;"
            :style="{ backgroundColor: item.WorkspaceID !== workspaceID ? '#E8CF85' : 'white' }"
            @click="handleChangeWorkspace(item.WorkspaceID)"
          >
            <v-flex xs6 style="padding:10px;">
              {{item.Name}}
            </v-flex>
            <v-flex xs6 style="padding:10px;">
              {{item.WorkspaceName}}
            </v-flex>
          </v-layout>
        </div>
        <div v-if="result.presentations.length > 0">
          <h1><v-icon large color="black">mdi-presentation-play</v-icon> {{$t('players.presentations', locale)}}</h1>
          <v-layout 
            wrap
            v-for="(item) of result.presentations"
            :key="item.ID"
            style="cursor: pointer;margin: 1px 0;"
            :style="{ backgroundColor: item.WorkspaceID !== workspaceID ? '#E8CF85' : 'white' }"
            @click="handleChangeWorkspace(item.WorkspaceID, true)"
          >
            <v-flex xs6 style="padding:10px;">
              {{item.Name}}
            </v-flex>
            <v-flex xs6 style="padding:10px;">
              {{item.WorkspaceName}}
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Search from '@/components/Search'
import utils from '@/services/utils'
import api from '@/services/api'
export default {
  components: {
    Search,
  },
  data: () => ({
    loading: false,
    searchValue: '',
    workspaces: [],
    result: {androids: [], presentations: []}
  }),
  computed: {
    ...mapState('app',['locale', 'playersWorkspace']),
    environment () {
      return 'players'
    },
    workspaceID () {
      return this.playersWorkspace
    },
  },
  mounted () {
    this.handleGetWorkspaces()
  },
  methods: {
    handleGetWorkspaces () {
      api.getAllWithoutLimit (this.environment, `v1/internal/users/${utils.getUser()}/workspaces`)
        .then(response => {
          this.workspaces = response
        })
    },
    async handleSearch (v) {
      this.loading = true
      this.searchValue = v
      this.result = {
        androids: [],
        presentations: []
      }
      if (this.searchValue.length === 0) {
        this.loading = false
        return
      }
      for (const workspaceID of this.workspaces.map(item => item.ID)) {
        this.result.presentations.push(...await this.handleGetPresentations(workspaceID))
        this.result.androids.push(...await this.handleGetAndroids(workspaceID))
      }
      this.loading = false
    },
    async handleGetPresentations (v) {
      const url = `v1/private/workspaces/${v}/presentations/`
      return await new Promise((resolve) => api.getAll (this.environment, url, 0, 10, this.searchValue)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          console.log(error)
          resolve([])
        }))
    },
    async handleGetAndroids (v) {
      const url = `v1/private/workspaces/${v}/android/`
      return await new Promise((resolve) => api.getAll (this.environment, url, 0, 10, this.searchValue)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          console.log(error)
          resolve([])
        }))
    },
    handleChangeWorkspace (v, isPresentation) {
      if (!v) return
      if (v !== this.workspaceID) {
        api.getItem (this.environment, `v1/internal/workspaces/`, v)
          .then(response => {
            if (!response) window.location.href = '/#/login'

            this.$store.state.app[`${this.environment}Workspace`] = v
            utils.setWorkspace(this.environment, v)
        
            this.$router.push(isPresentation ? '/players/presentations' : '/players/android')

          })
      } else {
        this.$router.push(isPresentation ? '/players/presentations' : '/players/android')
      }
    },
  },
}
</script>

